<template>
  <div>
    <a-card title="计费项目">
      <a-row gutter="12">
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="名称, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" @click="handelAdd"> <a-icon type="plus" />新增计费项目 </a-button>
        </div>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="items"
            :pagination="pagination"
            :scroll="{ y: 480 }"
            :loading="loading"
            @change="onChangeTable"
          >
            <template slot="action" slot-scope="value, item, index">
              <a-button-group size="small">
                <a-button icon="edit" @click="handleEdit(item)">编辑</a-button>
                <a-popconfirm title="确定删除吗" @confirm="destroy(item.id)">
                  <a-button type="danger" icon="delete" size="small">删除</a-button>
                </a-popconfirm>
              </a-button-group>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>
    <add-modal ref="modal_add" @ok="initData"></add-modal>
  </div>
</template>

<script>
import { chargeItemsList, chargeItemsDestroy } from "@/api/charging";

export default {
  name: "ChargeItemsPanel",
  data() {
    return {
      loading: false,
      searchForm: { page: 1, page_size: 48, search: "" },
      pagination: { current: 1, total: 0, pageSize: 48, showTotal: (total) => `共 ${total} 条` },
      items: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "项目名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  components: {
    AddModal: () => import("./modules/addModal"),
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.list();
    },
    handelAdd() {
      this.$refs.modal_add.show();
    },
    handleEdit(item) {
      this.$refs.modal_add.show(item);
    },
    setChargeConfig() {},
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    list() {
      this.loading = true;
      chargeItemsList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.selectedRowKeys = [];
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    destroy(id) {
      chargeItemsDestroy({ id }).then(() => {
        this.items.splice(
          this.items.findIndex((item) => item.id == id),
          1
        );
        this.$message.success("删除成功");
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
