import request from '@/utils/request';

// 收费项目列表
export function chargeItemsList(params) {
  return request({ url: `/charge_items/`, method: 'get', params })
}

// 收费项目新增
export function chargeItemsCreate(data) {
  return request({ url: `/charge_items/`, method: 'post', data })
}

// 收费项目编辑
export function chargeItemsEdit(data) {
  return request({ url: `/charge_items/${data.id}/`, method: 'put', data })
}

// 收费项目删除
export function chargeItemsDestroy(data) {
  return request({ url: `/charge_items/${data.id}/`, method: 'delete', data })
}

// 其它费用入库列表
export function chargeRecordsInList(params) {
  return request({ url: `/stock_in_charge_records/`, method: 'get', params })
}

// 其它费用入库总额
export function chargeRecordsInTotal(params) {
  return request({ url: `/stock_in_charge_records/total_amount/`, method: 'get', params })
}

// 其它费用入库新增
export function chargeRecordsInCreate(data) {
  return request({ url: `/stock_in_charge_records/`, method: 'post', data })
}

// 其它费用入库作废
export function chargeRecordsInVoid(data) {
  return request({ url: `/stock_in_charge_records/${data.id}/void/`, method: 'post', data })
}

// 其它费用出库列表
export function chargeRecordsOutList(params) {
  return request({ url: `/stock_out_charge_records/`, method: 'get', params })
}

// 其它费用出库总额
export function chargeRecordsOutTotal(params) {
  return request({ url: `/stock_out_charge_records/total_amount/`, method: 'get', params })
}

// 其它费用出库新增
export function chargeRecordsOutCreate(data) {
  return request({ url: `/stock_out_charge_records/`, method: 'post', data })
}

// 其它费用出库作废
export function chargeRecordsOutVoid(data) {
  return request({ url: `/stock_out_charge_records/${data.id}/void/`, method: 'post', data })
}

// 计费配置列表
export function chargConfigsList(params) {
  return request({ url: `/charge_configs/`, method: 'get', params })
}

// 计费配置新增
export function chargeConfigsCreate(data) {
  return request({ url: `/charge_configs/`, method: 'post', data })
}

// 计费配置编辑
export function chargeConfigsEdit(data) {
  return request({ url: `/charge_configs/${data.id}/`, method: 'put', data })
}

// 运输费用入库列表
export function chargeTransportInList(params) {
  return request({ url: `/stock_in_transport_records/`, method: 'get', params })
}

// 运输费用入库仓储总金额
export function chargeTransportInTotal(params) {
  return request({ url: `/stock_in_transport_records/total_amount/`, method: 'get', params })
}

// 运输费用入库新增
export function chargeTransportInCreate(data) {
  return request({ url: `/stock_in_transport_records/`, method: 'post', data })
}

// 运输费用入库作废
export function chargeTransportInVoid(data) {
  return request({ url: `/stock_in_transport_records/${data.id}/void/`, method: 'post', data })
}

// 运输费用出库列表
export function chargeTransportOutList(params) {
  return request({ url: `/stock_out_transport_records/`, method: 'get', params })
}

// 运输费用出库仓储总金额
export function chargeTransportOutTotal(params) {
  return request({ url: `/stock_out_transport_records/total_amount/`, method: 'get', params })
}

// 运输费用出库新增
export function chargeTransportOutCreate(data) {
  return request({ url: `/stock_out_transport_records/`, method: 'post', data })
}

// 运输费用出库作废
export function chargeTransportOutVoid(data) {
  return request({ url: `/stock_out_transport_records/${data.id}/void/`, method: 'post', data })
}

// 仓储费用列表
export function chargeWarehouseStorageList(params) {
  return request({ url: `/settlement_stock_records/`, method: 'get', params })
}

// 仓储费用仓储总金额
export function chargeWarehouseStorageTotal(params) {
  return request({ url: `/settlement_stock_records/total_amount/`, method: 'get', params })
}


// 装卸费用入库列表
export function chargeHandlingInList(params) {
  return request({ url: `/stock_in_handling_records/`, method: 'get', params })
}

// 装卸费用入库仓储总金额
export function chargeHandlingInTotal(params) {
  return request({ url: `/stock_in_handling_records/total_amount/`, method: 'get', params })
}

// 装卸费用入库新增
export function chargeHandlingInCreate(data) {
  return request({ url: `/stock_in_handling_records/`, method: 'post', data })
}

// 装卸费用入库作废
export function chargeHandlingInVoid(data) {
  return request({ url: `/stock_in_handling_records/${data.id}/void/`, method: 'post', data })
}

// 装卸费用出库列表
export function chargeHandlingOutList(params) {
  return request({ url: `/stock_out_handling_records/`, method: 'get', params })
}

// 装卸费用出库仓储总金额
export function chargeHandlingOutTotal(params) {
  return request({ url: `/stock_out_handling_records/total_amount/`, method: 'get', params })
}

// 装卸费用出库新增
export function chargeHandlingOutCreate(data) {
  return request({ url: `/stock_out_handling_records/`, method: 'post', data })
}

// 装卸费用出库作废
export function chargeHandlingOutVoid(data) {
  return request({ url: `/stock_out_handling_records/${data.id}/void/`, method: 'post', data })
}

// 费用合计列表
export function chargeSumList(params) {
  return request({ url: `/statistics/charge_reports/`, method: 'get', params })
}

// 出入库记录入库列表
export function storageRecordsInList(params) {
  return request({ url: `/stock_in_storage_records/`, method: 'get', params })
}

// 出入库记录入库新增
export function storageRecordsInCreate(data) {
  return request({ url: `/stock_in_storage_records/`, method: 'post', data })
}

// 出入库记录入库作废
export function storageRecordsInVoid(data) {
  return request({ url: `/stock_in_storage_records/${data.id}/void/`, method: 'post', data })
}

// 出入库记录出库列表
export function storageRecordsOutList(params) {
  return request({ url: `/stock_out_storage_records/`, method: 'get', params })
}

// 出入库记录出库新增
export function storageRecordsOutCreate(data) {
  return request({ url: `/stock_out_storage_records/`, method: 'post', data })
}

// 出入库记录出库作废
export function storageRecordsOutVoid(data) {
  return request({ url: `/stock_out_storage_records/${data.id}/void/`, method: 'post', data })
}

// 出入库记录入库列表
export function stockInAndOutInList(params) {
  return request({ url: `/stock_in_records/`, method: 'get', params })
}

// 出入库记录入库新增
export function stockInAndOutInCreate(data) {
  return request({ url: `/stock_in_records/`, method: 'post', data })
}

// 出入库记录出库列表
export function stockInAndOutOutList(params) {
  return request({ url: `/stock_out_records/`, method: 'get', params })
}

// 出入库记录出库新增
export function stockInAndOutOutCreate(data) {
  return request({ url: `/stock_out_records/`, method: 'post', data })
}